import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Chip,
    Container,
    Grid,
    IconButton,
    TableCell,
    TableRow,
    Typography,
    Stack,
    Tooltip,
    CircularProgress,
} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from "@mui/icons-material/Edit";
import { openPopUp } from "../../../store/reducer";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../hooks/page-title";
import { TableWrapper, UsePagination } from "../../../components";
import API from "../../../axios";
import ProjectUserForm from "./project-user-form";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomModal } from "../../../components";
import { BackButton } from "../../../assets";

export default function ProjectUser() {
    const project_Id = useLocation()?.state?.project_Id;
    const { selectedLang, language } = useSelector((state) => state.storeReducer);
    PageTitle(language[selectedLang]?.user_user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showForm, setShowForm] = useState(useLocation()?.state?.showForm || false );
    const [Id, setId] = useState();
    const [openM2, setOpenM2] = useState(false);
    const [singleRecord, setSingleRecord] = useState(null);
    const [record, setRecord] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const toggleForm = () => setShowForm(!showForm);
    const [isLoader, setIsLoader] = useState(false);
    const [projectStatus] = useState([]);
    const [staffMember] = useState([]);

    useEffect(() => {
        getRecord();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);



    const getRecord = async () => {
        setIsLoading(true);
        try {
            let url = `admin/get-project-users/${project_Id}`;
            let { data } = await API("get", url);
            setRecord(data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const updateRecord = (item) => {
        setSingleRecord(item);
        setShowForm(true);
    };

    const addRecord = () => {
        if (!!singleRecord) {
            setSingleRecord(null);
        }
        toggleForm();
    };

    const handleDelete = async () => {
        setIsLoader(true);
        try {
            await API("get", `admin/user/delete/${Id}/project/${project_Id}`);
            setIsLoader(false);
            dispatch(openPopUp({ message: language[selectedLang]?.delete_user, type: "success" }));
            setOpenM2(false);
            getRecord();
        } catch (error) {
            setIsLoader(false);
            dispatch(
                openPopUp({
                    message: language[selectedLang]?.error_msg,
                    type: "error",
                })
            );
        }
    };

    return (
        <Container maxWidth="false" sx={{ pt: 3, pb: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box onClick={() => navigate(-1)} sx={{ cursor: 'pointer' }}>
                        <Stack direction="row">
                            <img
                                src={BackButton}
                                alt="cdnImg"
                                style={{
                                    height: "24px",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    paddingRight: "6px",
                                }}
                            ></img>
                            <Typography component="h6" variant="h6">
                                {record?.project_info?.name} {language[selectedLang]?.user_user}
                            </Typography>
                        </Stack>
                    </Box>

                </Grid>
                <Grid item xs={6}>
                    <Stack
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="right"
                    >
                        {!showForm && (
                            <Button onClick={addRecord} variant="contained">
                                {language[selectedLang]?.common_add}
                            </Button>
                        )}
                    </Stack>

                </Grid>
                {showForm && (
                    <Grid item xs={12}>
                        <ProjectUserForm
                            project_id={project_Id}
                            userData = {singleRecord}
                            projectStatus={projectStatus}
                            staffMember={staffMember}
                            afterSubmit={() => {
                                getRecord('');
                                toggleForm();
                            }}
                            closeForm={() => addRecord()}
                        />
                    </Grid>
                )}
                {!showForm && (
                    <Grid item xs={12} sx={{ overflow: "auto" }}>
                        <TableWrapper
                            thContent={
                                <TableRow>
                                    <TableCell sx={{ color: "#fff" }}>
                                        {language[selectedLang]?.common_name}
                                    </TableCell>
                                    <TableCell sx={{ color: "#fff" }}>
                                        {language[selectedLang]?.common_email}
                                    </TableCell>
                                    <TableCell sx={{ color: "#fff", display: { xs: 'none', sm: 'table-cell' } }}>
                                        {language[selectedLang]?.common_user_status}
                                    </TableCell>
                                    <TableCell sx={{ color: "#fff" }}>
                                        {language[selectedLang]?.common_action}
                                    </TableCell>
                                </TableRow>
                            }
                            spanTd={3}
                            isLoading={isLoading}
                            isContent={!!record?.users.length}
                        >
                            {record?.users.map((v, i) => (
                                <TableRow key={i}>
                                    <TableCell>{v?.name ? v?.name : "--"}</TableCell>
                                    <TableCell>{v?.email ? v?.email : "--"}</TableCell>
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                        {v.active ? (
                                            <Chip label={language[selectedLang]?.common_active} color="primary" />
                                        ) : (
                                            <Chip label={language[selectedLang]?.common_disabled} />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={language[selectedLang]?.edit}>
                                            <IconButton
                                                onClick={() => updateRecord(v)}
                                                color="primary"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={language[selectedLang]?.delete}>
                                            <IconButton
                                                onClick={() => {
                                                    setOpenM2(true);
                                                    setId(v.id);
                                                }}
                                                color="error"
                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableWrapper>
                        {!!record && record?.last_page > 1 && (
                            <Box component="div" sx={{ mt: 2 }}>
                                <UsePagination
                                    total={record?.total}
                                    perPage={record?.per_page}
                                    page={page}
                                    setPage={setPage}
                                />
                            </Box>
                        )}
                    </Grid>
                )}
                <Grid item xs={12}>
                    {!showForm && !!record && record?.last_page > 1 && (
                        <Box component="div" sx={{ mt: 2 }}>
                            <UsePagination
                                total={record?.total}
                                perPage={record?.per_page}
                                page={page}
                                setPage={setPage}
                            />
                        </Box>
                    )}
                </Grid>
            </Grid>
            {openM2 ? (
                <CustomModal
                    handleClose={() => setOpenM2(false)}
                    children={
                        <Box py={3}>
                            <Typography mb={2} variant="h6" sx={{ textAlign: "center" }}>
                                {language[selectedLang]?.delete_message_message}
                            </Typography>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >
                                <Button variant="contained" onClick={() => setOpenM2(false)}>
                                    {language[selectedLang]?.common_no}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="info"
                                    onClick={() => handleDelete()}
                                    disabled={isLoader}
                                >
                                    {isLoader && <CircularProgress size={16} color="primary" />}
                                    {language[selectedLang]?.common_yes}
                                </Button>
                            </Stack>
                        </Box>
                    }
                />
            ) : null}
        </Container>
    );
}
