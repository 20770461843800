import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Box,
  Switch,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import API from "../../../axios";
import { InputField } from "../../../components";
import { openPopUp } from "../../../store/reducer";
import AdIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

export default function ProjectUserForm({
  project_id = null,
  userData = null,
  afterSubmit = () => { },
  closeForm,
}) {
  const [formErrors, setFormErrors] = useState();
  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [myUser, setMyUser]  = useState(userData);
  const [isFormReady, setIsFormReady]  = useState(false);
  const [formData, setFormData] = useState([
    {
      name: '',
      email: '',
      status: true,
      project_id: project_id
    }
  ]);

  const [activeSwitch, setActiveSwitch] = useState(myUser === null ? true : myUser?.active);
  const dispatch = useDispatch();

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormReady]);
  const handleNameValue = (e, itemIndex) => {
    var value = e.target.value;
    formData[itemIndex].name = value
    e.target.value = formData[itemIndex].name;
  };

  const isValidEmail = async () => {
    const lastRecord = formData[formData.length - 1];
    return await API("get", `admin/availableEmail?email=${lastRecord.email}&project_id=${project_id}`);
  };
  function hasDuplicates() {

    // Extract the email values from the formData array
    const emails = formData.map(item => item.email);
    // Create a new Set from the email values to remove duplicates
    const uniqueEmails = new Set(emails);
    // If the size of the Set is different from the original array length, duplicates exist
    return uniqueEmails.size !== emails.length;
  }
 
  const handleEmailChange = (e, itemIndex) => {
    var value = e.target.value;
    formData[itemIndex].email = value;
    e.target.value = formData[itemIndex].email;
    setIsFormReady(true);
  };
  const handleStatus = (e, itemIndex) => {
    var value = e.target.checked;
    formData[itemIndex].status = value;
    e.target.checked = formData[itemIndex].status;
  }
  const handleInputDel = (itemIndex) => {
    const newEmailVal = [...formData];
    newEmailVal.splice(itemIndex, 1);
    setFormData(newEmailVal);
  };
  const handleMe = (e) => {
    
    const newName = e.target.value;
    setMyUser((prevUserData) => ({
      ...prevUserData,
      name: newName,
    }));
  
  };
  const handleForm = async (e) => {
    setFormErrors({});
    e.preventDefault();
    setIsLoading(true);
    let _url = myUser === null ? `admin/add-project-user/${project_id}` : `admin/update-project-user/${myUser.id}`
    let data  = myUser !== null ? myUser : {data : formData};
    
    try {
    let response =  await API("post", _url, data);
      setIsLoading(false);
      dispatch(
        openPopUp({
          message: myUser === null ? language[selectedLang]?.client_create : language[selectedLang]?.client_update,
          type: "success",
        })
      );
      afterSubmit();
    } catch (err) {
      console.log(err.response.data);
      let errObj = {};
      if (err?.response?.status === 422) {
        for (const [key, value] of Object.entries(
          err?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
      } else {
        dispatch(
          openPopUp({
            message:
              err?.response?.data?.message || language[selectedLang][err.response.data],
            type: "error",
          })
        );
      }
      setIsLoading(false);
    }
  };

  return (
    <Box component="form" autoComplete="off" onSubmit={handleForm}>
      <Box maxWidth="800px" mx="auto">
        {userData === null &&
          formData?.map((item, itemIndex) => {
            return (
              <Stack spacing={2} mt={5} direction="row" alignItems="flex-end" key={itemIndex}>

                <InputField
                  type="text"
                  label={language[selectedLang]?.common_name}
                  name="name"
                  placeholder={language[selectedLang]?.customer_name}
                  required
                  color="primary"
                  error={formErrors?.name}
                  onKeyUp={(e) => handleNameValue(e, itemIndex)}
                />
                <InputField
                  type="email"
                  label={language[selectedLang]?.common_email}
                  name="other_email"
                  placeholder="client@example.com"
                  error={formErrors?.email}
                  onKeyUp={(e) => handleEmailChange(e, itemIndex)}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={2}
                >
                  <Typography variant="body1">
                    {language[selectedLang]?.common_user_status}
                  </Typography>
                  <Switch
                    checked={true}
                    onChange={(e) =>
                      handleStatus(e, itemIndex)
                    }
                  />
                </Stack>

                {
                  itemIndex === 0
                    ? <IconButton
                      color="#000" size="small" variant="contained"
                      sx={{ backgroundColor: "#FFBB00" }}
                      onClick={() => {
                        if (hasDuplicates()) {
                          dispatch(
                            openPopUp({
                              message: language[selectedLang]?.duplicate_email,
                              type: "error",
                            })
                          );
                        }
                        else {
                          isValidEmail()
                            .then((response) => {
                              if (response.data.status === false) { // Use strict equality (===) here
                                dispatch(
                                  openPopUp({
                                    message: language[selectedLang]?.use_another_email,
                                    type: "error",
                                  })
                                );
                                return;
                              }

                              const currentFormData = [...formData];
                              const updatedForm = () => {
                                currentFormData.push({
                                  name: '',
                                  email: '',
                                  status: true,
                                  project_id: project_id
                                });
                                return currentFormData;
                              }
                              setFormData(updatedForm)

                            });
                        }
                      }}
                    >
                      <AdIcon />
                    </IconButton>
                    : <IconButton
                      color="error" size="small"
                      onClick={() => handleInputDel(itemIndex)}
                    >
                      <DeleteIcon />
                    </IconButton>
                }

              </Stack>
            )
          })
        }
        {userData !== null &&
          <Stack spacing={2} mt={5} direction="row" alignItems="flex-end">

            <InputField
              type="text"
              label={language[selectedLang]?.common_name}
              name="name"
              placeholder={language[selectedLang]?.customer_name}
              required
              color="primary"
              error={formErrors?.name}
              value = {myUser?.name}
              onChange={handleMe}
            />
            <InputField
              type="email"
              label={language[selectedLang]?.common_email}
              name="other_email"
              placeholder="client@example.com"
              error={formErrors?.email}
              value={myUser?.email}
              disabled={true}
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mt={2}
            >
              <Typography variant="body1">
                {language[selectedLang]?.common_user_status}
              </Typography>
              <Switch
                checked={activeSwitch}
                onChange={(e)=> {
                  setActiveSwitch(e.target.checked);
                  myUser.active = e.target.checked;
                }
                }
              />
            </Stack>
          </Stack>
        }
        <Box textAlign="end" mt={5}>
          <Button onClick={closeForm} variant="contained" sx={{ mr: "4px" }}>
            {language[selectedLang]?.common_cancel}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={ myUser === null ?
            !isFormReady || isLoading
          : !myUser?.name}
          >
            {isLoading && <CircularProgress size={16} color="primary" />}
            {myUser === null
              ? language[selectedLang]?.common_add
              : language[selectedLang]?.common_save}
          </Button>
        </Box>

      </Box>
    </Box>
  );
}
