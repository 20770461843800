import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Box,
  Stack,
} from "@mui/material";

import { useDispatch } from "react-redux";
import API from "../../../axios";
import { InputField } from "../../../components";
import { openPopUp } from "../../../store/reducer";
import { useSelector } from "react-redux";

export default function TranslationForm({
  item = null,
  closeForm,
  afterSubmit = () => { },
}) {
  const [isLoading, setIsLoading] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  const [formData, setFormData] = useState({
    key: "",
    english: "",
    german: "",
    other: "",
    status: true,
    type: "ADD",
  });
  const dispatch = useDispatch();

  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  useEffect(() => {
    if (!!item) {
      setFormData({
        ...formData,
        id: item.id,
        key: item.key,
        english: item.english,
        german: item.german,
        other: item.other,
        status: item.active,
        type: "EDIT",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    var { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (
      formData.key.length >= 255 ||
      formData.english.length >= 255 ||
      formData.german.length >= 255 ||
      formData.other.length >= 255
    ) {
      setFormErrors({ [name]: `${name} less than 255 characters` });
    }
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const _fd = new FormData();
    _fd.append("key", formData.key.toLocaleLowerCase());
    _fd.append("english", formData.english);
    _fd.append("german", formData.german);
    _fd.append("other", formData.other ? formData.other : '');
    _fd.append("status", formData.status === true ? "1" : "0");
    let _url =
      formData.type === "ADD"
        ? "superadmin/translation"
        : `superadmin/translation/${formData.id}`;
    try {
      await API("post", _url, _fd);
      setIsLoading(false);
      if (formData.type === "ADD") {
        dispatch(
          openPopUp({
            message: language[selectedLang]?.super_admin_translation_create,
            type: "success",
          })
        );
      } else {
        dispatch(
          openPopUp({
            message: language[selectedLang]?.super_admin_translation_update,
            type: "success",
          })
        );
      }
      afterSubmit();
    } catch (err) {
      setIsLoading(false);
      let errObj = {};
      if (err?.response?.status === 422) {
        for (const [key, value] of Object.entries(
          err?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
      } else {
        dispatch(
          openPopUp({
            message: err?.response?.data?.message || language[selectedLang]?.error_msg,
            type: "error",
          })
        );
      }
    }
  };

  return (
    <Box component="form" autoComplete="off" onSubmit={handleForm}>
      <Box maxWidth="500px" mx="auto">
        <Stack spacing={2}>
          <Box>
            <InputField
              type="text"
              name="key"
              label={language[selectedLang]?.common_key}
              placeholder={language[selectedLang]?.common_key}
              error={formErrors?.key}
              required
              value={formData.key}
              onChange={handleChange}
            />
          </Box>
          <Box>
            <InputField
              type="text"
              label={language[selectedLang]?.common_english}
              name="english"
              required
              placeholder={language[selectedLang]?.common_english}
              error={formErrors?.english}
              value={formData.english}
              onChange={handleChange}
            />
          </Box>
          <Box>
            <InputField
              type="text"
              name="german"
              label={language[selectedLang]?.common_german}
              placeholder={language[selectedLang]?.common_german}
              error={formErrors?.german}
              value={formData.german}
              onChange={handleChange}
            />
          </Box>
          <Box>
            <InputField
              type="text"
              label={language[selectedLang]?.common_other}
              name="other"
              placeholder={language[selectedLang]?.common_other}
              error={formErrors?.other}
              value={formData.other}
              onChange={handleChange}
            />
          </Box>

          <Box textAlign="end">
            <Button onClick={closeForm} variant="contained" sx={{ mr: "4px" }}>
              {language[selectedLang]?.common_cancel}
            </Button>
            <Button variant="contained" type="submit"   disabled={
                !formData.key ||
                !formData.english ||
                isLoading
              }>
              {isLoading && <CircularProgress size={16} color="primary" />}
              {formData.type === "ADD" ? language[selectedLang]?.common_add : language[selectedLang]?.common_save}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}


