import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  Stack,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PageTitle from "../../../hooks/page-title";
import { TableWrapper, UsePagination } from "../../../components";
import API from "../../../axios";
import CatForm from "./cat-form";
import { CustomModal } from "../../../components";
import { openPopUp } from "../../../store/reducer";
import { useDispatch, useSelector } from "react-redux";
export default function SupCategories() {
  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  PageTitle(language[selectedLang]?.categories_categories);
  const dispatch = useDispatch();
  const [Id, setId] = useState();
  const [openM2, setOpenM2] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [singleRecord, setSingleRecord] = useState(null);
  const [record, setRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const toggleForm = () => setShowForm(!showForm);

  useEffect(() => {
    getRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRecord = async () => {
    setIsLoading(true);
    try {
      let { data } = await API("get", `superadmin/categories?page=${page}`);
      setRecord(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const updateRecord = (item) => {
    setSingleRecord(item);
    setShowForm(true);
  };

  const addRecord = () => {
    if (!!singleRecord) {
      setSingleRecord(null);
    }
    toggleForm();
  };
  const handleDelete = async () => {
    setIsLoader(true);
    try {
      await API("delete", `superadmin/delete-cat/${Id}`);
      setIsLoader(false);
      dispatch(openPopUp({ message: language[selectedLang]?.delete_file, type: "success" }));
      setOpenM2(false);
      getRecord();
    } catch (error) {
      setIsLoader(false);
      dispatch(
        openPopUp({
          message: language[selectedLang]?.error_msg,
          type: "error",
        })
      );
    }
  };
  return (
    <Container maxWidth="false" sx={{ pt: 3, pb: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography component="h6" variant="h6">
              {language[selectedLang]?.categories_categories}{" "}
            </Typography>
            {!showForm && (
              <Button onClick={addRecord} variant="contained">
                {language[selectedLang]?.common_add}
              </Button>
            )}
          </Stack>
        </Grid>
        {showForm && (
          <Grid item xs={12}>
            <CatForm
              item={singleRecord}
              afterSubmit={() => {
                getRecord();
                toggleForm();
              }}
              closeForm={() => addRecord()}
            />
          </Grid>
        )}
        {!showForm && (
          <Grid item xs={12} sx={{ overflow: "auto" }}>
            <TableWrapper
              thContent={
                <TableRow>
                  <TableCell sx={{ color: "#fff" }}>
                    {language[selectedLang]?.common_name}
                  </TableCell>
                  <TableCell sx={{ color: "#fff", display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>
                    {language[selectedLang]?.common_description}
                  </TableCell>
                  <TableCell sx={{ color: "#fff", display: { xs: 'none', sm: 'table-cell' } }}>
                    {language[selectedLang]?.common_status}
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    {language[selectedLang]?.common_action}
                  </TableCell>
                </TableRow>
              }
              spanTd={4}
              isLoading={isLoading}
              isContent={record?.data.length}
            >
              {record?.data.map((v, i) => (
                <TableRow key={i}>
                  <TableCell>{v.title}</TableCell>
                  <TableCell sx={{ display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>{v.description ? v.description : '--'}</TableCell>
                  <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                    {v.active ? (
                      <Chip label={language[selectedLang]?.common_active} color="primary" sx={{ display: { xs: 'none', sm: 'table-cell' } }} />
                    ) : (
                      <Chip label={language[selectedLang]?.common_disabled} sx={{ display: { xs: 'none', sm: 'table-cell' } }} />
                    )}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={language[selectedLang]?.edit}>
                      <IconButton
                        onClick={() => updateRecord(v)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={language[selectedLang]?.delete}>
                      <IconButton
                        onClick={() => {
                          setOpenM2(true);
                          setId(v.id);
                        }}
                        color="error"
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableWrapper>
          </Grid>
        )}
        <Grid item xs={12}>
          {!!record && record?.last_page > 1 && (
            <Box component="div" sx={{ mt: 2 }}>
              <UsePagination
                total={record?.total}
                perPage={record?.per_page}
                page={page}
                setPage={setPage}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      {openM2 ? (
        <CustomModal
          handleClose={() => setOpenM2(false)}
          children={
            <Box py={3}>
              <Typography mb={2} variant="h6" sx={{ textAlign: "center" }}>
                {language[selectedLang]?.delete_message_message}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Button variant="contained" onClick={() => setOpenM2(false)}>
                  {language[selectedLang]?.common_no}
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => handleDelete()}
                  disabled={isLoader}
                >
                  {isLoader && <CircularProgress size={16} color="primary" />}
                  {language[selectedLang]?.common_yes}
                </Button>
              </Stack>
            </Box>
          }
        />
      ) : null}
    </Container>
  );
}
