import { createSlice } from "@reduxjs/toolkit";

export const myReducer = createSlice({
  name: "storeReducer",
  initialState: {
    isLogged: false,
    user: null,
    token: null,
    openMenu: true,
    open: false,
    type: "success",
    message: "",
    project_status: "",
    selected_user: "",
    language: {},
    selectedLang: "german",
    project_name: "",
  },

  reducers: {
    toggleMenu: (state, data) => {
      state.openMenu = data.payload;
    },
    storeUser: (state, data) => {
      state.isLogged = true;
      state.user = data?.payload;
    },
    loginUser: (state, data) => {
      localStorage.setItem("userId", data.payload.user.id)
      localStorage.setItem("userEmail", data.payload.user.email)
      localStorage.setItem("userRole", data.payload.user.role)
      localStorage.setItem("@ACCESS_TOKEN", data.payload.access_token);
      state.isLogged = true;
      state.user = data.payload.user;
    },
    registerUser: (state, payload) => {
      state.isLogged = true;
      localStorage.setItem("@ACCESS_TOKEN", payload.token);
      state.user = payload.user;
    },
    logout: (state) => {
      localStorage.clear();
      state.user = null;
      state.project_status = null;
      state.isLogged = false;
    },
    openPopUp: (state, data) => {
      state.message = data?.payload?.message;
      state.type = data?.payload?.type ?? state.type;
      state.open = true;
    },
    closePopUp: (state, data) => {
      state.open = false;
    },
    Translation: (state, data) => {
      state.language = data?.payload;
    },
    langSetter: (state, data) => {
      localStorage.setItem("language", data.payload);
      state.selectedLang = data.payload;
    },
    setProStatus: (state, data) => {
      state.project_status = data.payload;
      if (data.payload) {
        localStorage.setItem("@Project_Status", data.payload);
      } else {
        localStorage.removeItem("@Project_Status");
      }
    },
    setProjectName: (state, data) => {
      state.project_name = data?.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  storeUser,
  toggleMenu,
  loginUser,
  registerUser,
  logout,
  closePopUp,
  openPopUp,
  setProStatus,
  Translation,
  langSetter,
  setProjectName
} = myReducer.actions;

export default myReducer.reducer;
