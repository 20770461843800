import React from "react";
import {
    Box,
    Typography,
    Stack,
} from "@mui/material";
import TrashIcon from "@mui/icons-material/Delete";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { Logo } from "../../assets";

export default function FolderCard({ file, id, progress, category_id, setOpenFolderDel, setFolderId, isManager ,fileCount}) {
    const navigate = useNavigate();
    return (
        <Box
            sx={cardContainer}
        >
            {isManager && (
                <TrashIcon
                    style={trashIcon}
                    onClick={() => {
                        setFolderId(file.id);
                        setOpenFolderDel(true);
                    }}
                />
            )}
            <Box
                sx={cardStyle}
                onClick={() => {
                    navigate(`/project/${id}/category/${category_id}/sub-category/${file.id}`, {
                        state: {
                            id: id,
                            category_id: category_id,
                            folder_name: file.name,
                            folder_id: file.id,
                            progress: progress
                        },
                    });
                }}
            >
                <Box sx={cardInner}>
                    <Box sx={imgWrapper}>
                        <img
                            src={Logo}
                            alt="logo"
                            width="150px"
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={cardFoo}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Box>
                        <Typography
                            variant="h6"
                            component="h6"
                            fontSize="16px"
                            textAlign="left"
                        >
                            {file?.name} ({fileCount})
                        </Typography>
                    </Box>
                    <Typography
                        variant="p"
                        component="p"
                        fontSize="14px"
                        textAlign="left"
                    >
                        {moment(file?.created_at).format("MMM Do YY")}
                    </Typography>
                </Stack>
            </Box>
        </Box>
    );
}

const cardAnimation = {
    transition: "all 150ms ease",
    boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 4px 2px",
};
const cardContainer = {
    boxShadow: "rgba(0, 0, 0, 0.4) 1px 1px 4px 1px",
    position: "relative",
    borderRadius: "12px",
    mb: 2,
    cursor: "pointer",
    height: "100%",
    "&:hover": cardAnimation,
};
const cardStyle = {
    width: "100%",
    minHeight: "250px",
    display: "flex",
    justifyContent: "flex-end",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
};
const cardInner = {
    backgroundColor: "#ffffffb0",
    p: 1,
    width: "100%",
    minHeight: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
};
const imgWrapper = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "150px",
};
const cardFoo = {
    backgroundColor: "#fff",
    px: 1,
    py: 1,
};
const trashIcon = {
    position: "absolute",
    top: "-14px",
    right: "-12px",
    zIndex: 999,
    cursor: 'pointer',
    color: '#000',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    backgroundColor: '#fff',
    padding: '10px',
    border: '1px solid #D5D5D5'
}