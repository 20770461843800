import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Box, Stack } from "@mui/material";

import { useDispatch } from "react-redux";
import API from "../../../axios";
import { InputField } from "../../../components";
import { openPopUp } from "../../../store/reducer";
import { useSelector } from "react-redux";

export default function ProFrom({
  item = null,
  afterSubmit = () => {},
  closeForm,
}) {
  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  const [isLoading, setIsLoading] = useState(false);

  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    title: "",
    progress: "",
    order: "",
    type: "ADD",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!item) {
      setFormData({
        ...formData,
        id: item.id,
        title: item.title,
        progress: item.progress,
        order: item.order,
        type: "EDIT",
        _method: "post",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    var { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const _fd = new FormData();
    _fd.append("title", formData.title);
    _fd.append("progress", formData.progress);
    _fd.append("order", formData.order);
    let _url =
      formData.type === "ADD"
        ? "superadmin/create-status"
        : `superadmin/update-single-status/${formData.id}`;
    if (formData.type === "EDIT") {
      _fd.append("_method", "post");
    }
    try {
      await API("post", _url, _fd);
      setIsLoading(false);
      if (formData.type === "ADD") {
        dispatch(
          openPopUp({
            message: language[selectedLang]?.cate_create,
            type: "success",
          })
        );
      } else {
        dispatch(
          openPopUp({
            message: language[selectedLang]?.cate_update,
            type: "success",
          })
        );
      }
      afterSubmit();
    } catch (err) {
      setIsLoading(false);
      let errObj = {};
      if (err?.response?.status === 422) {
        for (const [key, value] of Object.entries(
          err?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
      } else {
        dispatch(
          openPopUp({
            message:
              err?.response?.data?.message || language[selectedLang]?.error_msg,
            type: "error",
          })
        );
      }
    }
  };

  return (
    <Box component="form" autoComplete="off" onSubmit={handleForm}>
      <Box maxWidth="500px" mx="auto">
        <Stack spacing={3}>
          <Box>
            <InputField
              type="text"
              name="title"
              label={language[selectedLang]?.common_title}
              placeholder="Title"
              error={formErrors?.title}
              required
              value={formData.title}
              onChange={handleChange}
            />
          </Box>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <InputField
              type="number"
              label={language[selectedLang]?.common_project_progress}
              name="progress"
              placeholder="progress..."
              error={formErrors?.progress}
              required
              value={formData.progress}
              onChange={handleChange}
            />
            <InputField
              type="number"
              label={language[selectedLang]?.common_project_status}
              name="order"
              placeholder="order..."
              error={formErrors?.order}
              required
              value={formData?.order}
              onChange={handleChange}
            />
          </Stack>
          <Box textAlign="end">
            <Button onClick={closeForm} variant="contained" sx={{ mr: "4px" }}>
              {language[selectedLang]?.common_cancel}
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={
                !formData.title ||
                !formData.progress ||
                !formData.order ||
                isLoading
              }
            >
              {isLoading && <CircularProgress size={16} color="primary" />}
              {formData.type === "ADD"
                ? language[selectedLang]?.common_add
                : language[selectedLang]?.common_save}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
