import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import PageTitle from "../../../hooks/page-title";
import API from "../../../axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProStatus } from "../../../store/reducer";
import { CusProgress } from "../../../components";
import {
  hoverAnimation,
  cardContainer,
  cardStyle,
  cardFoo,
  imageFrame,
  smallLogo,
} from "../../../assets/css/client-css";

export default function AdDropFiles() {
  PageTitle("Project");
  const [categories, setCategories] = useState(null);
  const [project, setProject] = useState(null);
  const [categoryLen, setCategoryLen] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.storeReducer);
  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getCategories = async () => {
    setIsLoading(true);
    try {
      let { data: categoryContainer } = await API(
        "get",
        `categories?active=1&user_id=${user.id}`
      );
      let nonemptyCategories = [];
      categoryContainer.categories?.forEach((cat) => {
        if (cat.files.length > 0) {
          nonemptyCategories.push(cat);
        }
      });
      setCategoryLen(nonemptyCategories?.length)
      if (nonemptyCategories.length === 0) {
        nonemptyCategories.push(categoryContainer.categories[0]);
      }
      setProject(categoryContainer?.project);
      setCategories(nonemptyCategories);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="false" sx={{ pt: 3, pb: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {project?.project_status === null ? null : project?.project_status
            ?.progress === 0 ? null : (
            <CusProgress
              progress={project?.project_status?.progress}
              pStatus={project?.project_status_id}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Box mb={3}>
            <Stack direction="row">
              <Typography variant="h6">
                {language[selectedLang]?.categories_categories}({categoryLen})
              </Typography>
            </Stack>
          </Box>
          {/* <Typography
            variant="small"
            color="#FFBB00"
            sx={{ marginBottom: 2, marginLeft: 4 }}
          >
            {language[selectedLang]?.drop_files_files} /
          </Typography> */}
        </Grid>

        {isLoading ? (
          <Grid item xs={12}>
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress size={22} />
            </Box>
          </Grid>
        ) : (
          !!categories &&
          categories?.map((v, i) => {
            return (
              <Grid item lg={4} sm={6} xs={12} key={i} sx={hoverAnimation}>
                <Box sx={cardContainer}>
                  <Box
                    sx={[
                      cardStyle,
                      {
                        backgroundImage: user.image_url
                          ? `url(${user.image_url})`
                          : `url(${v.bg_url})`,
                      },
                    ]}
                    onClick={() => {
                      dispatch(setProStatus(project?.project_status?.title));
                      navigate(`/category/files`, {
                        state: {
                          id: user.id,
                          category_id: v.id,
                          project: project,
                          categoryLen: categoryLen,
                          progress: project?.project_status
                            ? project?.project_status?.progress
                            : 0,
                        },
                      });
                    }}
                  ></Box>
                  <Box sx={cardFoo}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={2}
                      sx={{ minHeight: "50px" }}
                    >
                      <Box sx={imageFrame}>
                        <img
                          style={smallLogo}
                          src={v.image_url}
                          alt="logo"
                          width="100%"
                          height="100%"
                        />
                      </Box>
                      <Typography
                        sx={{ fontSize: 20, fontWeight: "bold" }}
                        variant="small"
                      >
                        {v.name}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </Grid>
            );
          })
        )}
      </Grid>
    </Container>
  );
}
