import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Box,
  Switch,
  Stack,
  Typography,
  Tooltip,
  FormHelperText,
} from "@mui/material";

import { useDispatch } from "react-redux";
import API from "../../../axios";
import { InputField } from "../../../components";
import { openPopUp } from "../../../store/reducer";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useSelector } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { NoImg } from "../../../assets";
export default function CatForm({
  item = null,
  afterSubmit = () => {},
  closeForm,
}) {
  const fileTypes = ["jpg", "png", "jpeg"];
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFiles] = useState(null);
  const [bgImage, setBgImage] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  const [imageBlob2, setImageBlob2] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    status: true,
    image: "",
    bg_image: "",
    type: "ADD",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!item) {
      setFormData({
        ...formData,
        id: item.id,
        title: item.title,
        description: item.description,
        status: item.active,
        bg_image: item.bg_url,
        image: item.image_url,
        type: "EDIT",
        _method: "patch",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    var { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const _fd = new FormData();
    _fd.append("title", formData.title);
    _fd.append("description", formData.description);
    _fd.append("status", formData.status === true ? "1" : "0");
    _fd.append("image", file);
    _fd.append("bg_image", bgImage);
    let _url =
      formData.type === "ADD"
        ? "superadmin/categories"
        : `superadmin/categories/${formData.id}`;
    if (formData.type === "EDIT") {
      _fd.append("_method", "patch");
    }
    try {
      await API("post", _url, _fd);
      setIsLoading(false);
      if (formData.type === "ADD") {
        dispatch(
          openPopUp({
            message: language[selectedLang]?.cate_create,
            type: "success",
          })
        );
      } else {
        dispatch(
          openPopUp({
            message: language[selectedLang]?.cate_update,
            type: "success",
          })
        );
      }
      afterSubmit();
    } catch (err) {
      setIsLoading(false);
      let errObj = {};
      if (err?.response?.status === 422) {
        for (const [key, value] of Object.entries(
          err?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
      } else {
        dispatch(
          openPopUp({
            message:
              err?.response?.data?.message || language[selectedLang]?.error_msg,
            type: "error",
          })
        );
      }
    }
  };

  const handleImage = (file) => {
    setFiles(file);
    let blob = URL.createObjectURL(file);
    setImageBlob(blob);
    
  };

  const handleImage2 = (file) => {
    setBgImage(file);
    let blob2 = URL.createObjectURL(file);
    setImageBlob2(blob2);
  };

  return (
    <Box component="form" autoComplete="off" onSubmit={handleForm}>
      <Box maxWidth="500px" mx="auto">
        <Stack spacing={2}>
          <Box>
            <InputField
              type="text"
              name="title"
              label={language[selectedLang]?.common_title}
              placeholder="Title"
              error={formErrors?.title}
              required
              value={formData.title}
              onChange={handleChange}
            />
          </Box>
          <Box>
            <InputField
              type="text"
              label={language[selectedLang]?.common_description}
              name="description"
              placeholder="Description..."
              error={formErrors?.description}
              value={formData.description}
              onChange={handleChange}
            />
          </Box>
          <Box>
            {formData.image ? (
              <ItemLabel title="Icon" url={formData.image} />
            ) : (
              <Box variant="p" mb={1.5}>
                Icon
              </Box>
            )}
            <FileUploader
              handleChange={(file) => handleImage(file)}
              name="file"
              required
              multiple={false}
              maxSize={100}
              types={fileTypes}
            />
            {formErrors?.image && (
              <FormHelperText sx={{ color: "primary.main" }}>
                {formErrors?.image}
              </FormHelperText>
            )}
            <Box width="100%" height="100%" marginTop={3}>
              <img
                src={
                  imageBlob
                    ? imageBlob
                    : formData.image
                    ? formData.image
                    : NoImg
                }
                width="auto"
                style={{ maxWidth: "100%" }}
                height="auto"
                alt="test"
              />
            </Box>
          </Box>
          <Box>
            {formData.bg_image ? (
              <ItemLabel title="Background Image" url={formData.bg_image} />
            ) : (
              <Box variant="p" mb={1.5}>
                Background Image
              </Box>
            )}
            <FileUploader
              handleChange={(file) => handleImage2(file)}
              name="bg_image"
              required
              multiple={false}
              maxSize={100}
              types={fileTypes}
            />
            {formErrors?.bg_image && (
              <FormHelperText sx={{ color: "primary.main" }}>
                {formErrors?.image}
              </FormHelperText>
            )}
            <Box width="100%" height="100%" marginTop={3}>
              <img
                src={
                  imageBlob2
                    ? imageBlob2
                    : formData.bg_image
                    ? formData.bg_image
                    : NoImg
                }
                width="auto"
                style={{ maxWidth: "100%" }}
                height="auto"
                alt="test"
              />
            </Box>
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1">Status</Typography>{" "}
            <Switch
              checked={formData.status}
              onChange={(e) =>
                setFormData({ ...formData, status: e.target.checked })
              }
            />
          </Stack>
          <Box textAlign="end">
            <Button onClick={closeForm} variant="contained" sx={{ mr: "4px" }}>
              {language[selectedLang]?.common_cancel}
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={
                !formData.title ||
                !file ||
                !bgImage ||
                isLoading
              }
            >
              {isLoading && <CircularProgress size={16} color="primary" />}
              {formData.type === "ADD"
                ? language[selectedLang]?.common_add
                : language[selectedLang]?.common_save}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}

const ItemLabel = ({ title, url }) => {
  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  return (
    <Box style={{ display: "flex", alignItems: "center" }}>
      <Box variant="p" mb={1} mr={1}>
        {title}
      </Box>
      <a
        href={url}
        target="_blank"
        rel="noReferrer"
        style={{ color: "black", textDecoration: "none" }}
      >
        <Tooltip title={language[selectedLang]?.previous_image} placement="top">
          <RemoveRedEyeIcon
            fontSize="medium"
            style={{
              cursor: "pointer",
              color: "black",
              marginTop: "3px",
            }}
          />
        </Tooltip>
      </a>
    </Box>
  );
};
