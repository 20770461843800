const cardAnimation = {
  transition: "all 150ms ease",
  boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 4px 2px",
  zIndex: 99,
  transform: "scale(1.006)",
};

const cardContainer = {
  boxShadow: "rgba(0, 0, 0, 0.4) 1px 1px 4px 1px",
  borderRadius: "12px",
  position: "relative",
  overflow: "hidden",
  cursor: "pointer",
  height: "100%",
  "&:hover": cardAnimation,
};
const cardStyle = {
  width: "100%",
  minHeight: "250px",
  display: "flex",
  justifyContent: "flex-end",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
};

const cardFoo = {
  backgroundColor: "#fff",
  px: 1,
  py: 3, // Todo: check if height is set that way correct
};
const smallLogo = {
  width: "auto",
  height: "24px",
};
const hoverAnimation = {
    "&:hover": {
      transition: "all 150ms ease",
      zIndex: 99,
      transform: "scale(1.006)",
    },
  };

  

export { cardContainer, cardFoo, smallLogo, cardStyle ,hoverAnimation };
