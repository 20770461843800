import {
    SupHome,
    AdUser,
    ClShowFiles,
    SupCategories,
    AdDropFiles,
    ShowFiles,
    ShowFiles1,
    Profile,
    AccountActivation,
    AccountCreation,
    AccountSuspension,
    UpdateProjectStatus,
    ResetPasswordEmail,
    SendFromEmail,
    SupProjectStatus,
    Translations,
    Staff,
    ADStaffs,
    ProjectUsers,
    ShowFolder,
    ShowFolderClient,
    
} from "../pages";
import ClientProjects from '../pages/client/ClientProject'

export const appRoutes = [
    {
        path: "/",
        component: <SupHome />,
        protected: true,
        layout: true,
        type: 'SUPER_ADMIN',
    },
    {
        path: "/categories",
        component: <SupCategories />,
        protected: true,
        layout: true,
        type: 'SUPER_ADMIN',
    },
    {
        path: "/translation",
        component: <Translations />,
        protected: true,
        layout: true,
        type: 'SUPER_ADMIN',
    },
    {
        path: "/profile",
        component: <Profile />,
        protected: true,
        layout: true,
        type: 'CLIENT',
    },
    {
        path: "/project-status",
        component: <SupProjectStatus />,
        protected: true,
        layout: true,
        type: 'SUPER_ADMIN',
    },
    {
        path: "/profile",
        component: <Profile />,
        protected: true,
        layout: true,
        type: 'ADMIN',
    },
    {
        path: "/profile",
        component: <Profile />,
        protected: true,
        layout: true,
        type: 'SUPER_ADMIN',
    },
    {
        path: "/projects",
        component: <AdUser />,
        protected: true,
        layout: true,
        type: 'ADMIN',
    },
    {
        path: "/my-projects",
        component: <ClientProjects />,
        protected: true,
        layout: true,
        type: 'CLIENT',
    },
    {
        path: "/project/:id",
        component: <AdDropFiles />,
        protected: true,
        layout: true,
        type: 'ADMIN',
    },
    {
        path: "/project/category",
        component: <ShowFiles />,
        protected: true,
        layout: true,
        type: 'ADMIN',
    },
    {
        path: "/project/:projectId/category/:categoryId/sub-category/:id",
        component: <ShowFolder />,
        protected: true,
        layout: true,
        type: 'ADMIN',
    },
    {
        path: "/category/files",
        component: <ShowFiles1 />,
        protected: true,
        layout: true,
        type: 'CLIENT',
    },
    {
        path: "/files",
        component: <ClShowFiles />,
        protected: true,
        layout: true,
        type: 'CLIENT',
    },
    {
        path: "/files/:projectId/category/:categoryId/sub-category/:id",
        component: <ShowFolderClient />,
        protected: true,
        layout: true,
        type: 'CLIENT',
    },
    {
        path: "/settings/account-creation",
        component: <AccountCreation />,
        protected: true,
        layout: true,
        type: 'SUPER_ADMIN',
    },
    {
        path: "/settings/account-activation",
        component: <AccountActivation />,
        protected: true,
        layout: true,
        type: 'SUPER_ADMIN',
    },
    {
        path: "/settings/account-suspension",
        component: <AccountSuspension />,
        protected: true,
        layout: true,
        type: 'SUPER_ADMIN',
    },
    {
        path: "/settings/update-project-status",
        component: <UpdateProjectStatus />,
        protected: true,
        layout: true,
        type: 'SUPER_ADMIN',
    },
    {
        path: "/settings/reset-password-email",
        component: <ResetPasswordEmail />,
        protected: true,
        layout: true,
        type: 'SUPER_ADMIN',
    },
    {
        path: "/settings/send-from-email",
        component: <SendFromEmail />,
        protected: true,
        layout: true,
        type: 'SUPER_ADMIN',
    },
    {
        path: "/staff",
        component: <Staff />,
        protected: true,
        layout: true,
        type: 'SUPER_ADMIN',
    },
    {
        path: "/staffs",
        component: <ADStaffs />,
        protected: true,
        layout: true,
        type: 'ADMIN',
    },
    {
        path: "/projects/users/:id",
        component: <ProjectUsers />,
        protected: true,
        layout: true,
        type: 'ADMIN',
    },
];