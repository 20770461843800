import React, { useState, useEffect, Fragment } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import PageTitle from "../../../hooks/page-title";
import API from "../../../axios";
import { CusProgress, FileCard } from "../../../components";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BackButton, Logo } from "../../../assets";
import moment from "moment";
import {
  hoverAnimation,
  cardContainer,
  cardStyle,
  cardInner,
  imgWrapper,
  cardFoo,
} from "../../../assets/css/client-css";

export default function Files() {
  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  PageTitle(language[selectedLang]?.categories_categories);

  const [categories, setCategories] = useState(null);
  const [folderData, setFolderData] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(1);
  const id = useLocation()?.state?.id;
  const category_id = useLocation()?.state?.category_id;
  const categoryLen = useLocation()?.state?.categoryLen;
  const progress = useLocation()?.state?.progress;
  const navigate = useNavigate();

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getCategories = async () => {
    setIsLoading(true);
    try {
      let { data } = await API(
        "get",
        `getfiles?category_id=${category_id}&user_id=${id}&folder_id=0`
      );
      setCategories(data.categories);
      setFolderData(data.folders);
      setUser(data?.project);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="false" sx={{ pt: 3, pb: 3 }}>
      {isLoading ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress size={30} />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid container spacing={2} px={4}>
            <Grid item sm={12} lg={12} xs={12}>
              {progress === 0 ? null : (
                <Stack alignItems="center" sx={{ pt: 2 }}>
                  <CusProgress
                    progress={progress}
                    pStatus={user?.project_status?.id}
                  />
                </Stack>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Box mb={2}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box onClick={() => navigate(-1)} sx={{ cursor: "pointer" }}>
                  <Stack direction="row">
                    <img
                      src={BackButton}
                      alt="image1"
                      style={{
                        height: "24px",
                        marginTop: "auto",
                        marginBottom: "auto",
                        paddingRight: "6px",
                      }}
                    ></img>
                    <Typography variant="h6">
                      {language[selectedLang]?.categories_categories}({categoryLen})
                    </Typography>
                  </Stack>
                  <Box
                    sx={{
                      ml: 4,
                    }}
                  >
                    Folder ({folderData?.length})
                  </Box>
                  <Box
                    sx={{
                      ml: 4,
                    }}
                  >
                    Files ({categories?.[0]?.total_files})
                  </Box>
                </Box>
                {/* </a> */}
                {/* <Box py={1}>
                  <a
                    href="mailto:{user?.email}"
                    target="_blank"
                    rel="noReferrer"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {user?.name}
                  </a>
                </Box> */}
              </Stack>
            </Box>
          </Grid>

          {!!folderData?.length && (
            <Grid item xs={12} mb={2}>
              <Typography variant="h5" fontWeight="bold">
                {language[selectedLang]?.folders}
              </Typography>
            </Grid>
          )}
          <Grid container spacing={2} mb={2} ml={0}>
            {!!folderData &&
              folderData?.map((folder, folderIndex) => {
                return folder.map((_v, index) => {
                  return (
                    <Fragment key={index}>
                      <Grid item lg={4} sm={6} xs={12} sx={hoverAnimation}>
                        <Box sx={cardContainer}>
                          <Box
                            sx={cardStyle}
                            onClick={() => {
                              navigate(
                                `/files/${id}/category/${category_id}/sub-category/${_v.id}`,
                                {
                                  state: {
                                    id: id,
                                    category_id: category_id,
                                    folder_name: _v.name,
                                    folder_id: _v.id,
                                    progress: progress,
                                  },
                                }
                              );
                            }}
                          >
                            <Box sx={cardInner}>
                              <Box sx={imgWrapper}>
                                <img src={Logo} alt="logo" width="150px" />
                              </Box>
                            </Box>
                          </Box>
                          <Box sx={cardFoo}>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={2}
                            >
                              <Box>
                                <Typography
                                  variant="h6"
                                  component="h6"
                                  fontSize="16px"
                                  textAlign="left"
                                >
                                  {_v?.name} ({_v?.file_count})
                                </Typography>
                              </Box>
                              <Typography
                                variant="p"
                                component="p"
                                fontSize="14px"
                                textAlign="left"
                              >
                                {moment(_v?.created_at).format("MMM Do YY")}
                              </Typography>
                            </Stack>
                          </Box>
                        </Box>
                      </Grid>
                    </Fragment>
                  );
                });

              })}
          </Grid>

          {!!categories && (
            <Grid item xs={12} mb={3}>
              <Typography variant="h5" fontWeight="bold">
                {language[selectedLang]?.files}
              </Typography>
            </Grid>
          )}
          <Grid container spacing={2} ml={0}>
            {!!categories &&
              categories[0]?.files?.map((projectFiles, index) => {
                return projectFiles.map((v, vIndex) => {
                  return (
                    <Fragment key={vIndex}>
                      <Grid item lg={4} sm={6} xs={12} sx={hoverAnimation}>
                        <FileCard
                          file={v}
                          getCategories={getCategories}
                          created_date={categories?.[0]?.created_date}
                          image={!!categories ? categories[0]?.bg_url : ""}
                          isManager={false}
                          fileCount={v.file_count}
                        />
                      </Grid>
                    </Fragment>
                  );
                })
              })}
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

// function FileTypes({ file, image, frontImg, created_date }) {
//
//   const project = () => {
//     const link = `${process.env.REACT_APP_KEY}file/${file.id}`;
//     switch (file.extension) {
//       case "pdf":
//         return (
//           <Box sx={cardContainer}>
//             <a
//               href={link}
//               target="_blank"
//               rel="noReferrer"
//               style={{ color: "black", textDecoration: "none" }}
//             >
//               <Box
//                 sx={[
//                   cardStyle,
//                   {
//                     backgroundImage: file.thumbnail_url
//                       ? `url(${file.thumbnail_url})`
//                       : 'url("https://media.istockphoto.com/id/184601291/photo/xxxl-dark-concrete.jpg?b=1&s=170667a&w=0&k=20&c=KRRXq0P41imqerPjYF4EgX7QOdbybyprM8ofKYoIzT4=")',
//                   },
//                 ]}
//               >
//                 <Box sx={cardInner}>
//                   <Box sx={imgWrapper}>
//                     <img
//                       style={smallLogo}
//                       src={frontImg ? frontImg : Logo}
//                       alt="logo"
//                       width="150px"
//                     />
//                   </Box>
//                 </Box>
//               </Box>
//               <Box sx={cardFoo}>
//                 {/* TODO: add category image in front of white bottom section */}
//                 <Stack
//                   direction="row"
//                   justifyContent="space-between"
//                   alignItems="center"
//                   spacing={2}
//                 >
//                   <Box>
//                     <Typography
//                       variant="h6"
//                       component="h6"
//                       fontSize="16px"
//                       textAlign="left"
//                     >
//                       {file.name}
//                     </Typography>
//                     <Typography
//                       variant="p"
//                       component="p"
//                       fontSize="14px"
//                       textAlign="left"
//                     >
//                       {file.size}
//                     </Typography>
//                   </Box>
//                   <Typography
//                     variant="p"
//                     component="p"
//                     fontSize="14px"
//                     textAlign="left"
//                   >
//                     {created_date}
//                   </Typography>
//                 </Stack>
//               </Box>
//             </a>
//           </Box>
//         );
//       case "png":
//       case "jpg":
//       case "jpeg":
//         return (
//           <Box sx={cardContainer}>
//             <a
//               href={file.file_url}
//               target="_blank"
//               rel="noReferrer"
//               style={{ color: "black", textDecoration: "none" }}
//             >
//               <Box
//                 sx={[
//                   cardStyle,
//                   {
//                     backgroundImage: file.file_url
//                       ? `url(${file.file_url})`
//                       : 'url("https://media.istockphoto.com/id/184601291/photo/xxxl-dark-concrete.jpg?b=1&s=170667a&w=0&k=20&c=KRRXq0P41imqerPjYF4EgX7QOdbybyprM8ofKYoIzT4=")',
//                   },
//                 ]}
//               >
//                 <Box sx={cardInner}>
//                   <Box sx={imgWrapper}>
//                     <img
//                       src={frontImg ? frontImg : Logo}
//                       alt="logo"
//                       width="150px"
//                     />
//                   </Box>
//                 </Box>
//               </Box>
//               <Box sx={cardFoo}>
//                 <Stack
//                   direction="row"
//                   justifyContent="space-between"
//                   alignItems="center"
//                   spacing={2}
//                 >
//                   <Box>
//                     <Typography
//                       variant="h6"
//                       component="h6"
//                       fontSize="16px"
//                       textAlign="left"
//                     >
//                       {file.name}
//                     </Typography>
//                     <Typography
//                       variant="p"
//                       component="p"
//                       fontSize="14px"
//                       textAlign="left"
//                     >
//                       {file.size}
//                     </Typography>
//                   </Box>
//                   <Typography
//                     variant="p"
//                     component="p"
//                     fontSize="14px"
//                     textAlign="left"
//                   >
//                     {created_date}
//                   </Typography>
//                 </Stack>
//               </Box>
//             </a>
//           </Box>
//         );
//       case "doc":
//       case "docx":
//       case "zip":
//       case "svg":
//         return (
//           <Box sx={cardContainer}>
//             <a
//               href={file.file_url}
//               target="_blank"
//               rel="noReferrer"
//               style={{ color: "black", textDecoration: "none" }}
//             >
//               <Box
//                 sx={[
//                   cardStyle,
//                   {
//                     backgroundImage: image
//                       ? `url(${image})`
//                       : 'url("https://media.istockphoto.com/id/184601291/photo/xxxl-dark-concrete.jpg?b=1&s=170667a&w=0&k=20&c=KRRXq0P41imqerPjYF4EgX7QOdbybyprM8ofKYoIzT4=")',
//                   },
//                 ]}
//               >
//                 <Box sx={cardInner}>
//                   <Box sx={imgWrapper}>
//                     <img
//                       src={frontImg ? frontImg : Logo}
//                       alt="logo"
//                       width="150px"
//                     />
//                   </Box>
//                 </Box>
//               </Box>
//               <Box sx={cardFoo}>
//                 <Stack
//                   direction="row"
//                   justifyContent="space-between"
//                   alignItems="center"
//                   spacing={2}
//                 >
//                   <Box>
//                     <Typography
//                       variant="h6"
//                       component="h6"
//                       fontSize="16px"
//                       textAlign="left"
//                     >
//                       {file.name}
//                     </Typography>
//                     <Typography
//                       variant="p"
//                       component="p"
//                       fontSize="14px"
//                       textAlign="left"
//                     >
//                       {file.size}
//                     </Typography>
//                   </Box>
//                   <Typography
//                     variant="p"
//                     component="p"
//                     fontSize="14px"
//                     textAlign="left"
//                   >
//                     {created_date}
//                   </Typography>
//                 </Stack>
//               </Box>
//             </a>
//           </Box>
//         );
//       case "csv":
//         return (
//           <Box sx={cardContainer}>
//             <a
//               href={file.file_url}
//               target="_blank"
//               rel="noReferrer"
//               style={{ color: "black", textDecoration: "none" }}
//             >
//               <Box
//                 sx={[
//                   cardStyle,
//                   {
//                     backgroundImage: image
//                       ? `url(${image})`
//                       : 'url("https://media.istockphoto.com/id/184601291/photo/xxxl-dark-concrete.jpg?b=1&s=170667a&w=0&k=20&c=KRRXq0P41imqerPjYF4EgX7QOdbybyprM8ofKYoIzT4=")',
//                   },
//                 ]}
//               >
//                 <Box sx={cardInner}>
//                   <Box sx={imgWrapper}>
//                     <img
//                       src={frontImg ? frontImg : Logo}
//                       alt="logo"
//                       width="150px"
//                     />
//                   </Box>
//                 </Box>
//               </Box>
//               <Box sx={cardFoo}>
//                 <Stack
//                   direction="row"
//                   justifyContent="space-between"
//                   alignItems="center"
//                   spacing={2}
//                 >
//                   <Box>
//                     <Typography
//                       variant="h6"
//                       component="h6"
//                       fontSize="16px"
//                       textAlign="left"
//                     >
//                       {file.name}
//                     </Typography>
//                     <Typography
//                       variant="p"
//                       component="p"
//                       fontSize="14px"
//                       textAlign="left"
//                     >
//                       {file.size}
//                     </Typography>
//                   </Box>
//                   <Typography
//                     variant="p"
//                     component="p"
//                     fontSize="14px"
//                     textAlign="left"
//                   >
//                     {created_date}
//                   </Typography>
//                 </Stack>
//               </Box>
//             </a>
//           </Box>
//         );
//       case "xlsx":
//         return (
//           <Box sx={cardContainer}>
//             <a
//               href={file.file_url}
//               target="_blank"
//               rel="noReferrer"
//               style={{ color: "black", textDecoration: "none" }}
//             >
//               <Box
//                 sx={[
//                   cardStyle,
//                   {
//                     backgroundImage: image
//                       ? `url(${image})`
//                       : 'url("https://media.istockphoto.com/id/184601291/photo/xxxl-dark-concrete.jpg?b=1&s=170667a&w=0&k=20&c=KRRXq0P41imqerPjYF4EgX7QOdbybyprM8ofKYoIzT4=")',
//                   },
//                 ]}
//               >
//                 <Box sx={cardInner}>
//                   <Box sx={imgWrapper}>
//                     <img
//                       src={frontImg ? frontImg : Logo}
//                       alt="logo"
//                       width="150px"
//                     />
//                   </Box>
//                 </Box>
//               </Box>
//               <Box sx={cardFoo}>
//                 <Stack
//                   direction="row"
//                   justifyContent="space-between"
//                   alignItems="center"
//                   spacing={2}
//                 >
//                   <Box>
//                     <Typography
//                       variant="h6"
//                       component="h6"
//                       fontSize="16px"
//                       textAlign="left"
//                     >
//                       {file.name}
//                     </Typography>
//                     <Typography
//                       variant="p"
//                       component="p"
//                       fontSize="14px"
//                       textAlign="left"
//                     >
//                       {file.size}
//                     </Typography>
//                   </Box>
//                   <Typography
//                     variant="p"
//                     component="p"
//                     fontSize="14px"
//                     textAlign="left"
//                   >
//                     {created_date}
//                   </Typography>
//                 </Stack>
//               </Box>
//             </a>
//           </Box>
//         );
//       default:
//         return "";
//     }
//   };
//
//   return (
//     <>
//       <Box className="file">{project()}</Box>
//     </>
//   );
// }
