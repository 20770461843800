import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import PageTitle from "../../../hooks/page-title";
import API from "../../../axios";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProjectName, setProStatus } from "../../../store/reducer";
import { CusProgress } from "../../../components";
import { BackButton } from "../../../assets";
import {
  cardContainer,
  cardFoo,
  smallLogo,
  cardStyle,
} from "../../../assets/css/custom-css";

export default function AdDropFiles() {
  const [categories, setCategories] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(1);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  PageTitle(language[selectedLang]?.user_user);
  const userRole = localStorage.getItem('userRole');

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (user) {
      dispatch(setProjectName(user?.name));
    }
  }, [user]);

  const getCategories = async () => {
    // setIsLoading(true);
    try {
      let { data } = await API(
        "get",
        `admin/categories?active=1&user_id=${id}`
      );
      setCategories(data.categories);
      localStorage.setItem("@Selected_User", data.user?.name);
      setUser(data.project);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const countCategories = (categories) => {
    let count = 0;
    if (categories) {
      categories.forEach((cat) => {
        if (cat.files.length > 0) {
          count++;
        }
      });
    }
    return count;
  }
  return (
    <Container maxWidth="false" sx={{ pt: 3, pb: 3 }}>
      <Grid container spacing={2} mt={1}>
        <Grid container spacing={2} px={2}>
          <Grid item sm={12} lg={12} xs={12}>
            {user?.project_status === null ? null : user?.project_status
              ?.progress === 0 ? null : (
              <CusProgress
                progress={user?.project_status?.progress}
                pStatus={user?.project_status?.id}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Stack direction="row" justifyContent="space-between">
              {/* <a
                target="_self"
                href="/#;"
                rel="noReferrer"
                style={{ color: "black", textDecoration: "none" }}
              > */}
              <Box onClick={() => navigate(-1)} sx={{ cursor: "pointer" }}>
                <Stack direction="row">
                  <img
                    src={BackButton}
                    alt="cdnImg"
                    style={{
                      height: "24px",
                      marginTop: "auto",
                      marginBottom: "auto",
                      paddingRight: "6px",
                    }}
                  ></img>
                  <Typography variant="h6">
                    {language[selectedLang]?.categories_categories} (
                    {userRole === 'CLIENT' && (countCategories(categories))}
                    {userRole !== 'CLIENT' && (categories?.length)}
                    )
                  </Typography>
                </Stack>
              </Box>
              {/* </a> */}
              {/* <Box py={1}>
                <a
                  href="mailto:{user?.email}"
                  target="_blank"
                  rel="noReferrer"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  {user?.name}
                </a>
              </Box> */}
            </Stack>
          </Box>
          {/* <Typography
            variant="small"
            color="#FFBB00"
            sx={{ cursor: "pointer", marginBottom: 2, marginLeft: 4 }}
            onClick={() => navigate(-1)}
          >
            Project/
          </Typography> */}
        </Grid>

        {isLoading ? (
          <Grid item xs={12}>
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress size={22} />
            </Box>
          </Grid>
        ) : (
          !!categories &&
          categories?.map((v, i) => {
            if (v.files.length > 0 && userRole === 'CLIENT') {
              return (
                <Grid item lg={4} sm={6} xs={12} key={i}>
                  <Box sx={cardContainer}>
                    <Box
                      sx={[
                        cardStyle,

                        {
                          backgroundImage: user.image_url
                            ? `url(${user.image_url})`
                            : `url(${v.bg_url})`,
                        },
                      ]}
                      onClick={() => {
                        dispatch(setProStatus(user?.project_status?.title));
                        // dispatch(setProjectName(v?.name));
                        navigate("/project/category", {
                          state: {
                            id: user.id,
                            category_id: v.id,
                            progress: user?.project_status
                              ? user?.project_status?.progress
                              : 0,
                          },
                        });
                      }}
                    ></Box>
                    <Box sx={cardFoo}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Box>
                          <img
                            style={smallLogo}
                            src={v.image_url}
                            alt="logo"
                            width="40px"
                            height="40px"
                          />
                        </Box>
                        <Typography
                          sx={{ fontSize: 20, fontWeight: "bold" }}
                          variant="small"
                        >
                          {v.name}
                        </Typography>
                      </Stack>
                    </Box>
                  </Box>
                </Grid>
              );
            }
            if(userRole !== 'CLIENT') {
              return (
                <Grid item lg={4} sm={6} xs={12} key={i}>
                  <Box sx={cardContainer}>
                    <Box
                      sx={[
                        cardStyle,

                        {
                          backgroundImage: user.image_url
                            ? `url(${user.image_url})`
                            : `url(${v.bg_url})`,
                        },
                      ]}
                      onClick={() => {
                        dispatch(setProStatus(user?.project_status?.title));
                        // dispatch(setProjectName(v?.name));
                        navigate("/project/category", {
                          state: {
                            id: user.id,
                            category_id: v.id,
                            progress: user?.project_status
                              ? user?.project_status?.progress
                              : 0,
                          },
                        });
                      }}
                    ></Box>
                    <Box sx={cardFoo}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Box>
                          <img
                            style={smallLogo}
                            src={v.image_url}
                            alt="logo"
                            width="40px"
                            height="40px"
                          />
                        </Box>
                        <Typography
                          sx={{ fontSize: 20, fontWeight: "bold" }}
                          variant="small"
                        >
                          {v.name}
                        </Typography>
                      </Stack>
                    </Box>
                  </Box>
                </Grid>
              );
            }

          })
        )}
      </Grid>
    </Container>
  );
}
