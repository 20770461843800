import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './index.css';
import {
  Button,
  CircularProgress,
  Box,
  Stack,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import API from "../../../axios";
import { InputField, SelectBox } from "../../../components";
import { openPopUp } from "../../../store/reducer";
import { FileUploader } from "react-drag-drop-files";
import { NoImg } from "../../../assets";

const fileTypes = ["jpg", "jpeg", "png"];

export default function UserForm({
  item = null,
  afterSubmit = () => { },
  closeForm,
  projectStatus = [],
  staffMember = []
}) {
  const [formErrors, setFormErrors] = useState();
  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [imageBlob, setImageBlob] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    image: {},
    project_status: "",
    staff_member: "",
    other_email: [""],
    type: "ADD",
    imageBlog: "",
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!item) {
      setFormData({
        ...formData,
        id: item.id,
        name: item.name,
        project_status: item?.project_status ? item?.project_status?.id : "",
        staff_member: item?.staff_member ? item?.staff_member?.id : "",
        other_email: [""],
        progress: item.progress,
        imageBlog: item?.image_url,
        type: "EDIT",
        _method: "patch",
      });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (e) => {
    var { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleEmailChange = (e, itemIndex) => {
    var { name, value } = e.target;
    const newEmailVal = [...formData.other_email];
    newEmailVal[itemIndex] = value;
    setFormData({ ...formData, [name]: newEmailVal });
  };

  const handleUpload = async (file) => {
    if (file === null) {
      dispatch(openPopUp({ message: language[selectedLang]?.select_file, type: "warning" }));
      return;
    }
    setFormData({ ...formData, image: file });
  };


  const handleForm = async (e) => {
    setFormErrors({});
    e.preventDefault();
    setIsLoading(true);
    let _url =
      formData.type === "ADD" ? "admin/users" : `admin/users/${formData.id}`;
    let fd = new FormData();
    fd.append("name", formData.name);
    if (item?.staff_member) {
      fd.append("staff_member", formData?.staff_member);
    } else {
      fd.append("staff_member", formData?.staff_member);
    }
    if (item?.project_status) {
      fd.append("project_status", formData?.project_status);
    } else {
      fd.append("project_status", formData?.project_status);
    }
    if (formData.image.name) {
      fd.append("image", formData.image);
    }
    if (formData.type === "EDIT") {
      fd.append("_method", "patch");
    }

    try {
      let response = await API("post", _url, fd, { "Content-Type": "multipart/form-data" });
      setIsLoading(false);
      if (response.data?.status === true) {
        dispatch(
          openPopUp({
            message: response?.data?.message || language[selectedLang]?.user_another_email,
            type: "error",
          })
        );
        return;
      }
      if (formData.type === "ADD") {
        dispatch(openPopUp({ message: language[selectedLang]?.project_create, type: 'success' }));
        navigate(`/projects/users/${response.data.id}`, {
          state: {
            project_Id: response.data.id,
            showForm: true
          }
        });
        return;
      }
      dispatch(openPopUp({ message: language[selectedLang]?.project_update, type: 'success' }));
      afterSubmit();
    } catch (err) {
      let errObj = {};
      if (err?.response?.status === 422) {
        for (const [key, value] of Object.entries(
          err?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
      } else if (err?.response?.status === 415) {
        dispatch(
          openPopUp({
            message: err?.response?.data?.message || language[selectedLang]?.duplicate_email,
            type: "error",
          })
        );
      } else {
        dispatch(
          openPopUp({
            message: err?.response?.data?.message || language[selectedLang]?.error_msg,
            type: "error",
          })
        );
      }
      setIsLoading(false);
    }
  };

  const handleImage = (file) => {
    let blob = URL.createObjectURL(file)
    setImageBlob(blob)
  };

  return (
    <Box component="form" autoComplete="off" >
      <Box maxWidth="500px" mx="auto">
        <Stack spacing={2}>
          <InputField
            type="text"
            label={language[selectedLang]?.common_name}
            name="name"
            placeholder={language[selectedLang]?.customer_name}
            required
            color="primary"
            initValue={formData?.name}
            value={formData.name}
            error={formErrors?.name}
            onChange={handleChange}
          />
      
          <Box>
            <SelectBox
              items={projectStatus}
              label={language[selectedLang]?.project_status_project_status}
              name="project_status"
              size="small"
              required
              initValue={
                item !== null
                  ? item?.project_status?.id
                  : formData?.project_status
              }
              value={formData?.project_status}
              error={formErrors?.project_status}
              handleChange={handleChange}
            />
          </Box>
          <Box>
            <SelectBox
              items={staffMember}
              label={language[selectedLang]?.admin_staff_member}
              name="staff_member"
              size="small"
              required
              initValue={
                item?.staff_member
                  ? item?.staff_member?.id
                  : formData?.staff_member
              }
              value={formData?.staff_member}
              error={formErrors?.project_status}
              handleChange={handleChange}
            />
          </Box>
          <Box>
            <FileUploader
              handleChange={(e) => {
                handleImage(e);
                handleUpload(e);
              }}
              name="image"
              maxSize={500}
              types={fileTypes}
            />
            <Box width="100%" height="100%" marginTop={3} >
              <img
                src={imageBlob ? imageBlob : formData.imageBlog ? formData.imageBlog : NoImg}
                alt="ImagePreview"
                width="auto"
                style={{ maxWidth: "100%" }}
                height="auto"
              />
            </Box>
          </Box>
          <Box textAlign="end">
            <Button onClick={closeForm} variant="contained" sx={{ mr: "4px" }}>
              {language[selectedLang]?.common_cancel}
            </Button>
            <Button variant="contained" type="submit" disabled={
              !formData.project_status ||
              !formData.name ||
              !formData.staff_member ||
              isLoading
            }
              onClick={handleForm}
            >
              {isLoading && <CircularProgress size={16} color="primary" />}
              {formData.type === "ADD" ? language[selectedLang]?.common_add : language[selectedLang]?.common_save}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
