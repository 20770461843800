const hoverAnimation = {
  "&:hover": {
    transition: "all 150ms ease",
    zIndex: 99,
    transform: "scale(1.006)",
  },
};

const cardAnimation = {
  transition: "all 150ms ease",
  boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 4px 2px",
};
const cardContainer = {
  boxShadow: "rgba(0, 0, 0, 0.4) 1px 1px 4px 1px",
  position: "relative",
  borderRadius: "12px",
  mb: 2,
  cursor: "pointer",
  height: "100%",
  "&:hover": cardAnimation,
};
const cardStyle = {
  width: "100%",
  minHeight: "250px",
  display: "flex",
  justifyContent: "flex-end",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
};
const cardInner = {
  backgroundColor: "#ffffffb0",
  p: 1,
  width: "100%",
  minHeight: "200px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};
const imgWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "150px",
};
const cardFoo = {
  backgroundColor: "#fff",
  px: 1,
  py: 1,
};
const imageFrame = {
  width: "50px",
  position: "relative",
};
const smallLogo = {
  width: "auto",
  height: "24px",
};

const styles = {
  hoverAnimation: {
    ":hover": {
      transition: "all 150ms ease",
      zIndex: 99,
      transform: "scale(1.006)",
    },
  },
  cardAnimation: {
    transition: "all 150ms ease",
    boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 4px 2px",
  },
  cardContainer: {
    boxShadow: "rgba(0, 0, 0, 0.4) 1px 1px 4px 1px",
    borderRadius: "12px",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
  },
  imageFrame: {
    height: "50px",
    width: "50px",
    position: "relative",
  },
  smallLogo: {
    maxHeight: "100%",
    maxWidth: "100%",
    width: "auto",
    height: "auto",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  cardStyle: {
    width: "100%",
    minHeight: "250px",
    display: "flex",
    justifyContent: "flex-end",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  imgWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  cardFoo: {
    backgroundColor: "#fff",
  },
  asideImage: {
    width: "100%",
    height: "100%",
    borderRadius: "12px",
  },
};

export {
  hoverAnimation,
  cardContainer,
  cardStyle,
  cardInner,
  imgWrapper,
  cardFoo,
  imageFrame,
  smallLogo,
  styles,
};
