import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  Stack,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PageTitle from "../../../hooks/page-title";
import { TableWrapper, UsePagination } from "../../../components";
import API from "../../../axios";
import CatForm from "./trans-form";
import { CustomModal } from "../../../components";
import { openPopUp, Translation } from "../../../store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
export default function Translations() {
  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  PageTitle(language[selectedLang]?.super_admin_translations);
  const dispatch = useDispatch();
  const [Id, setId] = useState();
  const [openM2, setOpenM2] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [singleRecord, setSingleRecord] = useState(null);
  const [record, setRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const toggleForm = () => setShowForm(!showForm);
  const [uploadModal, setUploadModal] = useState(false);
  const [file, setFile] = useState();
  const [isLoader1, setIsLoader1] = useState();

  useEffect(() => {
    getRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getRecord = async () => {
    setIsLoading(true);
    try {
      let { data } = await API("get", `superadmin/translations?page=${page}`);
      setRecord(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const updateRecord = (item) => {
    setSingleRecord(item);
    setShowForm(true);
  };

  const addRecord = () => {
    if (!!singleRecord) {
      setSingleRecord(null);
    }
    toggleForm();
  };
  const handleDelete = async () => {
    setIsLoader(true);
    try {
      await API("delete", `superadmin/delete-translation/${Id}`);
      setIsLoader(false);
      dispatch(
        openPopUp({
          message: language[selectedLang]?.super_admin_translation_delete,
          type: "success",
        })
      );
      setOpenM2(false);
      getRecord();
    } catch (error) {
      setIsLoader(false);
      dispatch(
        openPopUp({
          message: language[selectedLang]?.error_msg,
          type: "error",
        })
      );
    }
  };
  const uploadLang = async () => {
    if (!file) return;
    setIsLoader(true);
    const _fd = new FormData();
    _fd.append("file", file);
    try {
      await API("post", "superadmin/upload-lang-file", _fd);
      setIsLoader(false);
      dispatch(
        openPopUp({
          message: language[selectedLang]?.super_admin_translation_create,
          type: "success",
        })
      );
      setUploadModal(false);
      getRecord();
    } catch (err) {
      setIsLoader(false);
      if (err?.response?.status === 422) {
        dispatch(
          openPopUp({
            message: err?.response?.data?.detail?.file[0] || language[selectedLang]?.error_msg,
            type: "error",
          })
        );
      } else {
        dispatch(
          openPopUp({
            message: err?.response?.data?.message || language[selectedLang]?.error_msg,
            type: "error",
          })
        );
      }
    }
  };
  const refreshLang = async () => {
    setIsLoader1(true);
    try {
      const res = await API("get", "get-csvfile");
      setIsLoader1(false);
      dispatch(Translation(res?.data));
    } catch (err) {
      setIsLoader1(false);
      dispatch(
        openPopUp({
          message: err?.response?.data?.message || "Translations does not load!",
          type: "error",
        })
      );
    }
  };
  const downloadLang = async () => {
    window.location.href =
      `${process.env.REACT_APP_KEY}api/download-csv`;
  };
  const fileTypes = ["lan"];
  return (
    <Container maxWidth="false" sx={{ pt: 3, pb: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            flexDirection={{ sm: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Typography component="h6" variant="h6" sx={{ marginBottom: { xs: '15px', sm: '0px' } }}>
              {language[selectedLang]?.super_admin_translations}
            </Typography>
            <Stack direction="row" flexWrap="wrap" mb={1} justifyContent="flex-end" spacing={1} gap={1}>
              <Button
                variant="contained"
                onClick={() => refreshLang()}
                disabled={isLoader1}
              >
                {isLoader1 && <CircularProgress size={16} color="primary" />}
                {language[selectedLang]?.common_refresh}
              </Button>
              <Button
                onClick={() => setUploadModal(true)}
                variant="contained"
                sx={{ mb: 1 }}
              >
                {language[selectedLang]?.common_upload_csv}
              </Button>
              <Button variant="contained" onClick={() => downloadLang()} sx={{ mb: 1 }}>
                {language[selectedLang]?.common_download_csv}
              </Button>
              {!showForm && (
                <Button onClick={addRecord} variant="contained" sx={{ mb: 1 }}>
                  {language[selectedLang]?.common_add}
                </Button>
              )}
            </Stack>
          </Stack>
        </Grid>
        {showForm && (
          <Grid item xs={12}>
            <CatForm
              item={singleRecord}
              afterSubmit={() => {
                getRecord();
                toggleForm();
              }}
              closeForm={() => addRecord()}
            />
          </Grid>
        )}
        {!showForm && (
          <Grid item xs={12} sx={{ overflow: "auto" }}>
            <TableWrapper
              thContent={
                <TableRow>
                  <TableCell sx={{ color: "#fff" }}>
                    {language[selectedLang]?.common_key}
                  </TableCell >
                  <TableCell sx={{ color: "#fff", display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>
                    {language[selectedLang]?.common_english}
                  </TableCell>
                  <TableCell sx={{ color: "#fff", display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>
                    {language[selectedLang]?.common_german}
                  </TableCell>
                  <TableCell sx={{ color: "#fff", display: { xs: 'none', sm: 'table-cell', md: 'table-cell' } }}>
                    {language[selectedLang]?.common_other}
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    {language[selectedLang]?.common_action}
                  </TableCell>
                </TableRow>
              }
              spanTd={4}
              isLoading={isLoading}
              isContent={record?.data.length}
            >
              {record?.data.map((v, i) => (
                <TableRow key={i}>
                  <TableCell>{v.key}</TableCell>
                  <TableCell sx={{ display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>{v.english}</TableCell>
                  <TableCell sx={{ display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>{v.german ?? "--"}</TableCell>
                  <TableCell sx={{ display: { xs: 'none', sm: 'table-cell', md: 'table-cell' } }}>{v.other ?? "--"}</TableCell>
                  <TableCell>
                    <Tooltip title={language[selectedLang]?.edit}>
                      <IconButton
                        onClick={() => updateRecord(v)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={language[selectedLang]?.tooltip_delete_file}>
                      <IconButton
                        onClick={() => {
                          setOpenM2(true);
                          setId(v.id);
                        }}
                        color="error"
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableWrapper>
          </Grid>
        )}

        <Grid item xs={12}>
          {!showForm && !!record && record?.last_page > 1 && (
            <Box component="div" sx={{ mt: 2 }}>
              <UsePagination
                total={record?.total}
                perPage={record?.per_page}
                page={page}
                setPage={setPage}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      {openM2 ? (
        <CustomModal
          handleClose={() => setOpenM2(false)}
          children={
            <Box py={3}>
              <Typography mb={2} variant="h6" sx={{ textAlign: "center" }}>
                {language[selectedLang]?.delete_message_message}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Button variant="contained" onClick={() => setOpenM2(false)}>
                  {language[selectedLang]?.common_no}
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => handleDelete()}
                  disabled={isLoader}
                >
                  {isLoader && <CircularProgress size={16} color="primary" />}
                  {language[selectedLang]?.common_yes}
                </Button>
              </Stack>
            </Box>
          }
        />
      ) : null}
      {uploadModal ? (
        <CustomModal
          handleClose={() => setUploadModal(false)}
          children={
            <Box py={3}>
              <Typography mb={2} variant="h6" sx={{ textAlign: "center" }}>
                {language[selectedLang]?.upload_csv}
              </Typography>
              <FileUploader
                handleChange={(file) => setFile(file)}
                name="file"
                required
                multiple={false}
                maxSize={100}
                types={fileTypes}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="end"
                spacing={1}
                mt={3}
              >
                <Button
                  variant="contained"
                  onClick={() => setUploadModal(false)}
                >
                  {language[selectedLang]?.common_cancel}
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => uploadLang()}
                  disabled={isLoader}
                >
                  {isLoader && <CircularProgress size={16} color="primary" />}
                  {language[selectedLang]?.common_add}
                </Button>
              </Stack>
            </Box>
          }
        />
      ) : null}
    </Container>
  );
}
