import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Box,
  Stack,
  Typography,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import API from "../../axios";
import { InputField, PasswordInputField } from "../../components";
import { openPopUp } from "../../store/reducer";
import { useNavigate } from "react-router-dom";



export default function Profile() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.storeReducer);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: ""
  });
  const [secureEntry, setSecureEntry] = useState(true);
  const [secureEntry1, setSecureEntry1] = useState(true);
  const [formErrors, setFormErrors] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!user) {
      setFormData({
        ...formData,
        name: user.name,
        email: user.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    var { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleForm = async (e) => {
    e.preventDefault();
    setFormErrors({});
    setIsLoading(true);
    try {
      await API("post", `update-my-profile`, formData);
      setIsLoading(false);
      if (user.role === "SUPER_ADMIN") navigate("/");
      else if (user.role === "ADMIN") navigate("/projects");
      else if (user.role === "Client") navigate("/files");
      dispatch(openPopUp({ message: language[selectedLang]?.profile_update, type: "success" }));
    } catch (err) {
      setIsLoading(false);
      let errObj = {};
      if (err?.response?.status === 422) {
        for (const [key, value] of Object.entries(
          err?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
      } else {
        dispatch(
          openPopUp({
            message: err?.response?.data?.message || language[selectedLang]?.error_msg,
            type: "error",
          })
        );
      }
    }
  };

  return (
    <Box component="form" autoComplete="off" onSubmit={handleForm}>
      <Box maxWidth="500px" mx="auto">
        <Typography variant="h6" my={4}>
          {language[selectedLang]?.update_profile}
        </Typography>
        <Stack spacing={2}>
          <InputField
            type="text"
            label={language[selectedLang]?.common_name}
            name="name"
            placeholder="Admin"
            required
            value={formData?.name}
            error={formErrors?.name}
            onChange={handleChange}
          />
          {user.role === "SUPER_ADMIN" && (
            <InputField
              type="email"
              label={language[selectedLang]?.common_email}
              name="email"
              value={formData?.email}
              error={formErrors?.email}
              onChange={handleChange}
            />
          )}
          <PasswordInputField
            label={language[selectedLang]?.common_password}
            name="password"
            onChange={handleChange}
            value={formData.password}
            error={formErrors?.password}
            secureEntry={secureEntry}
            setSecureEntry={setSecureEntry}
          />
          <PasswordInputField
            label={language[selectedLang]?.common_c_password}
            name="confirm_password"
            onChange={handleChange}
            value={formData.confirm_password}
            error={formErrors?.confirm_password}
            secureEntry={secureEntry1}
            setSecureEntry={setSecureEntry1}
          />

          <Box textAlign="end">
            <Button
              onClick={() =>
                user.role === "SUPER_ADMIN"
                  ? navigate("/")
                  : user.role === "ADMIN"
                    ? navigate("/projects")
                    : user.role === "CLIENT"
                      ? navigate("/files")
                      : null
              }
              variant="contained"
              sx={{ mr: "4px" }}
            >
              {language[selectedLang]?.common_cancel}
            </Button>
            <Button variant="contained" type="submit" disabled={!formData.name || isLoading}>
              {isLoading && <CircularProgress size={16} color="primary" />}
              {language[selectedLang]?.common_update}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
