import React, { useState, useEffect, Fragment } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Stack,
  Button,
} from "@mui/material";
import PageTitle from "../../../hooks/page-title";
import API from "../../../axios";
import { CusProgress, CustomModal, FileCard } from "../../../components";
import { openPopUp } from "../../../store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { BackButton } from "../../../assets";
import { hoverAnimation } from "../../../assets/css/client-css";

const fileTypes = [
  "pdf",
  "jpg",
  "jpeg",
  "png",
  "doc",
  "docx",
  "csv",
  "svg",
  "zip",
];

export default function Files() {
  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  PageTitle(language[selectedLang]?.categories_categories);

  const [fileId, setFileId] = useState("");
  const [openFileDel, setOpenFileDel] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [categories, setCategories] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [page] = useState(1);

  const id = useLocation()?.state?.id;
  const category_id = useLocation()?.state?.category_id;
  const folder_name = useLocation()?.state?.folder_name;
  const folder_id = useLocation()?.state?.folder_id;
  const progress = useLocation()?.state?.progress;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getCategories = async () => {
    setIsLoading(true);
    try {
      let { data } = await API(
        "get",
        `admin/getfiles?category_id=${category_id}&user_id=${id}&folder_id=${folder_id}`
      );
      setCategories(data.categories);
      console.log(
        "🚀 ~ file: files.js:78 ~ getCategories ~ data.categories",
        data.categories[0]?.files?.length
      );
      setUser(data.user);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleUpload = async (file) => {
    if (file === null) {
      dispatch(
        openPopUp({
          message: language[selectedLang]?.select_file,
          type: "warning",
        })
      );
      return;
    }
    let arr = [];
    for (let i = 0; i < file.length; i++) {
      let singleImage = file[i];
      arr.push(singleImage);
    }
    setUploadLoader(true);
    let fd = new FormData();
    arr.forEach((v, i) => {
      fd.append(`file_name[${i}]`, v);
    });
    fd.append(`user_id`, user?.id);
    fd.append(`category_id`, category_id);
    fd.append(`folder_id`, folder_id);
    try {
      await API("post", "admin/files", fd);
      setUploadLoader(false);
      dispatch(
        openPopUp({
          message: language[selectedLang]?.upload_file,
          type: "success",
        })
      );
      getCategories();
    } catch (err) {
      if (err?.response?.status === 422) {
        dispatch(
          openPopUp({
            message: err?.response?.data?.details?.file_name[0],
            type: "error",
          })
        );
      } else {
        dispatch(
          openPopUp({
            message: language[selectedLang]?.error_msg,
            type: "error",
          })
        );
      }
      setUploadLoader(false);
    }
  };

  const deleteFile = async () => {
    setIsLoader(true);
    try {
      await API("delete", `admin/files/${fileId}`);
      setIsLoader(false);
      dispatch(
        openPopUp({
          message: language[selectedLang]?.tooltip_delete_file,
          type: "success",
        })
      );
      getCategories();
    } catch (error) {
      setIsLoader(false);
      dispatch(
        openPopUp({
          message: language[selectedLang]?.error_msg,
          type: "error",
        })
      );
    }
  };

  return (
    <Container maxWidth="false" sx={{ pt: 3, pb: 3 }}>
      {isLoading ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress size={30} />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid container spacing={2} px={4}>
            <Grid item sm={12} lg={12} xs={12}>
              {progress === 0 ? null : (
                <Stack alignItems="center" sx={{ pt: 2 }}>
                  <CusProgress
                    progress={progress}
                    pStatus={user?.project_status?.id}
                  />
                </Stack>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Box mb={2}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box onClick={() => navigate(-1)} sx={{ cursor: "pointer" }}>
                  <Stack direction="row">
                    <img
                      src={BackButton}
                      alt="image1"
                      style={{
                        height: "24px",
                        marginTop: "auto",
                        marginBottom: "auto",
                        paddingRight: "6px",
                      }}
                    ></img>
                    <Typography variant="h6">
                      {folder_name} ({categories?.[0]?.total_files})
                    </Typography>
                  </Stack>
                </Box>
                {/* </a> */}
                {/* <Box py={1}>
                  <a
                    href="mailto:{user?.email}"
                    target="_blank"
                    rel="noReferrer"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {user?.name}
                  </a>
                </Box> */}
              </Stack>
            </Box>
            <Box mb={3}>
              <Stack
                direction={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                gap={4}
              >
                <Stack direction="row" alignItems="flex-end">
                  <img
                    src={!!categories ? categories[0]?.image : undefined}
                    width="auto"
                    height="30px"
                    style={{ marginRight: "5px" }}
                    alt="icon.png"
                  />
                  <Typography variant="h6">
                    {!!categories && categories[0]?.name}
                  </Typography>
                </Stack>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  alignItems="flex-end"
                  spacing={2}
                >
                  {uploadLoader ? (
                    <CircularProgress size={32} color="primary" />
                  ) : (
                    <Box sx={{ width: "100%" }}>
                      <FileUploader
                        handleChange={(e) => handleUpload(e)}
                        name="file"
                        multiple
                        maxSize={500}
                        types={fileTypes}
                      />
                    </Box>
                  )}
                </Stack>
              </Stack>
            </Box>
          </Grid>

          <Grid container spacing={2} ml={0}>
            {!!categories &&
              categories[0]?.files?.map((v, index) => {
                return (
                  <Fragment key={index}>
                    <Grid item lg={4} sm={6} xs={12} sx={hoverAnimation}>
                      <FileCard
                        file={v}
                        getCategories={getCategories}
                        created_date={categories?.[0]?.created_date}
                        image={!!categories ? categories[0]?.bg_url : ""}
                        setOpenFileDel={setOpenFileDel}
                        setFileId={setFileId}
                        isManager={true}
                      />
                    </Grid>
                  </Fragment>
                );
              })}
          </Grid>
        </Grid>
      )}
      {openFileDel ? (
        <CustomModal
          handleClose={() => setOpenFileDel(false)}
          children={
            <Box py={3}>
              <Typography mb={2} variant="h6" sx={{ textAlign: "center" }}>
                {language[selectedLang]?.delete_message_message}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Button
                  variant="contained"
                  onClick={() => setOpenFileDel(false)}
                >
                  {language[selectedLang]?.common_no}
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => {
                    deleteFile();
                    setOpenFileDel(false);
                  }}
                  disabled={isLoader}
                >
                  {isLoader && <CircularProgress size={16} color="primary" />}
                  {language[selectedLang]?.common_yes}
                </Button>
              </Stack>
            </Box>
          }
        />
      ) : null}
    </Container>
  );
}
